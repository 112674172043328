import React, { useState, useEffect } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';
import { BsList, BsGearFill } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { BiSolidExit } from "react-icons/bi";
import { collapses } from './mainData';
import MainOptions from './mainOptions';
import AsyncComponent from './asyncComponent';
import { ConfirmationBox } from '../../Utils/CustomMessagingModal';
import app from '../../../services/app'
import img from '../../../assets/img/profile-img.jpg';

export default function AdminHome() {
    const history = useHistory();
    const [showMenu, setShowMenu] = useState(true);
    const [openCollapse, setOpenCollapse] = useState(null);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [activeConfigurations, setActiveConfigurations] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [userFullName, setUserFullName] = useState('');
    const [userImage, setuserImage] = useState('');

    //função que será executada se o usuário clicar em Confirmar quando aparecer o modal de confirmação
    const handleConfirmationExit = () => {
      localStorage.clear();
      history.push('/login');
    };

    //função que será executada se o usuário clicar em Cancelar quando aparecer o modal de confirmação
    const handleCancelExit = () => {
      setShowConfirmation(false);
    };

    //função que irá abrir o modal de confirmação da solicitação personalizado
    const handleExitClick = () => {
      setShowConfirmation(true);
    };

    const handleSubMenuClick = (id) => {
      setActiveSubMenu(id);
      setActiveConfigurations(false);
    };

    const toggleMenu = () => {
      setShowMenu(!showMenu);
    };

    const handleConfigurationsClick = () => {
      setActiveConfigurations(true);
      setOpenCollapse(null);
      setActiveSubMenu(null);
    };

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setShowMenu(false);
        }
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    const handleCollapseClick = (id) => {
      setOpenCollapse(openCollapse === id ? null : id);
    };

    useEffect(() => {
      const fetchData = async () => {
        try {

          const user = await app.get(`/api/users/v1/username/${localStorage.getItem('username')}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
          });
    
          setUserFullName(user.data.fullName);
          setuserImage(user.data.image);
        } catch (error) {
            console.error('Falha ao buscar dados do usuário:', error);

            //se der bad request é pq o token expirou
            if(error.code === 'ERR_BAD_REQUEST') {
                localStorage.clear();
                history.push('/login#expired');
            }
        }
      };
    
      fetchData();
    }, []);

    return (
      <div className='main'>
        <Row>
          <Col md={showMenu ? 3 : 0} className={`col-menu-admin ${!showMenu ? 'hide-menu' : 'show-menu'}`}>
            <div className='menu-button'>
              { showMenu ? <IoCloseSharp size={25} className='btn-main' onClick={toggleMenu}/> : <BsList size={30} className='btn-toggle' onClick={toggleMenu}/> }
            </div>
            {showMenu && (
              <div className='menu scrollable-card-body'>
                <div className='main-visible'>
                  <Row>
                    <Col md={12} className='pb-3' style={{ textAlign: 'center' }}>
                        <Image className='rounded-circle' width={150} height={150} src={userImage != null ? userImage : img}/>
                    </Col>
                    <Col md={12} className='pb-0' style={{ textAlign: 'center' }}>
                        <h5>Bem vindo, {userFullName}!</h5>
                    </Col>
                    <Col md={12} className='pb-4' style={{ textAlign: 'center' }}>
                      <Link 
                        className={`configurations-main ${activeConfigurations ? 'active' : ''}`}
                        to="/dashboard/configurations"
                        onClick={handleConfigurationsClick}
                      >
                        <span title='Configurações'>
                          <BsGearFill 
                            className='user-icons' 
                            size={28}/>
                        </span>
                      </Link>
                      <span title='Sair'>
                          <BiSolidExit 
                            className='user-icons' 
                            size={28} 
                            onClick={handleExitClick}
                          />
                      </span>
                      <ConfirmationBox
                        show={showConfirmation}
                        message="Deseja sair do Dashboard?"
                        onConfirm={handleConfirmationExit}
                        onCancel={handleCancelExit}
                      />
                    </Col>
                    {collapses.map((collapse) => (
                      <MainOptions
                        key={collapse.id}
                        collapse={collapse}
                        openCollapse={openCollapse}
                        handleCollapseClick={handleCollapseClick}
                        activeSubMenu={activeSubMenu}
                        handleSubMenuClick={handleSubMenuClick}
                      />
                    ))}
                  </Row>
                </div>
              </div>
            )}
          </Col>
          <Col className="col-menu-admin" md={showMenu ? 9 : 12}>
            <div className={`menu-container ${!showMenu ? '' : 'menu-container-closed'}`}>
              <div className={`${!showMenu ? 'scrollable-container' : 'scrollable-container-view'}`}>
                <Switch>
                  <Route exact path="/dashboard" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Orders/orders')}
                    />
                  )} />
                  <Route exact path="/dashboard/configurations" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Configurations/configurations')}
                    />
                  )} />
                  <Route exact path="/dashboard/company" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Informations/company')}
                    />
                  )} />
                  <Route exact path="/dashboard/links" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Informations/links')}
                    />
                  )} />
                  <Route exact path="/dashboard/banners" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Banners/banners')}
                    />
                  )} />
                  <Route exact path="/dashboard/portCategory" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Portfolio/category')}
                    />
                  )} />
                  <Route exact path="/dashboard/portGallery" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Portfolio/gallery')}
                    />
                  )} />
                  <Route exact path="/dashboard/portClients" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Portfolio/clients')}
                    />
                  )} />
                  <Route exact path="/dashboard/prodCategory" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Products/category')}
                    />
                  )} />
                  <Route exact path="/dashboard/products" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Products/products')}
                    />
                  )} />
                  <Route exact path="/dashboard/orders" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Orders/orders')}
                    />
                  )} />
                  <Route exact path="/dashboard/order/:id" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Orders/orderView')}
                    />
                  )} />
                  <Route path="*" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../../../pages/NotFound')}
                    />
                  )} />
                </Switch>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
}