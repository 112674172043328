import React from 'react';
import { IoImagesOutline, IoStorefrontOutline, IoBagCheckOutline } from "react-icons/io5";
import { BiCategory } from "react-icons/bi";
import { GoInfo } from "react-icons/go";

export const collapses = [
  {
    id: 1,
    icon: <GoInfo className="info-icon" size={22} />,
    text: "Informações Gerais",
    submenus: [
      { id: 1, text: "Empresa", href: "/dashboard/company" },
      { id: 2, text: "Links", href: "/dashboard/links" },
    ]
  },
  {
    id: 2,
    icon: <IoImagesOutline className="info-icon" size={22} />,
    text: "Banners",
    submenus: [
      { id: 1, text: "Visualizar", href: "/dashboard/banners" },
    ]
  },
  {
    id: 3,
    icon: <BiCategory className="info-icon" size={22} />,
    text: "Portifólio",
    submenus: [
      { id: 1, text: "Categorias", href: "/dashboard/portCategory" },
      { id: 2, text: "Galeria", href: "/dashboard/portGallery" },
      { id: 3, text: "Clientes", href: "/dashboard/portClients" },
    ]
  },
  {
    id: 4,
    icon: <IoStorefrontOutline className="info-icon" size={22} />,
    text: "Produtos",
    submenus: [
      { id: 1, text: "Categorias", href: "/dashboard/prodCategory" },
      { id: 2, text: "Produtos", href: "/dashboard/products" },
    ]
  },
  {
    id: 5,
    icon: <IoBagCheckOutline className="info-icon" size={22} />,
    text: "Pedidos",
    submenus: [
      { id: 1, text: "Visualizar", href: "/dashboard/orders" },
    ]
  },
];