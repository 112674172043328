import React, { useState, useEffect } from 'react';

export default function AsyncComponent({ importComponent }) {
  const [component, setComponent] = useState(null);

  useEffect(() => {
    const loadComponent = async () => {
      try {
        const { default: Component } = await importComponent();
        setComponent(<Component />);
      } catch (error) {
        console.error('Error while loading component:', error);
      }
    };

    loadComponent();
  }, [importComponent]);

  return component;
}
