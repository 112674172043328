import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Form, FormControl, Button, Container } from 'react-bootstrap';
import { FaShoppingCart, FaSearch } from "react-icons/fa";
import Badge from '@mui/joy/Badge';
import { styled } from '@mui/system';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import storageUpdate from '../Cart/storageUpdate';
import app from '../../services/app';
import logo from '../../assets/img/default-logo.png';

export default function Header() {
  const location = useLocation();
  const history = useHistory();
  const [image, setCompanyImage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [storedItems, setStoredItems] = useState([]);
  const searchInputRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  async function handleSearch(e) {
    e.preventDefault();
  
    setIsDropdownOpen(false);
    history.push('/shop?search=' + searchQuery);
    setSearchQuery('');
  };
  
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#7401DF',
      color: '#fff',
      fontWeight: 'bold',
    },
  }));

  const handleClick = () => {
    setIsDropdownOpen(false);
  };

  const updateCart = () => {
    const itemsFromLocalStorage = JSON.parse(localStorage.getItem('cartItems'));
    setStoredItems(itemsFromLocalStorage || []);
  };

  useEffect(() => {

    // Defina a função de atualização do carrinho
    storageUpdate.setUpdateCartCallback(updateCart);

    const fetchData = async () => {
      try {
        const user = await app.get('/api/company/v1/view/1');
  
        setCompanyImage(user.data.image);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, []);

  return (
    <Navbar expand="lg" bg="black" variant="dark" style={{ padding: "5px" }}>
      <div className="d-lg-none mr-auto">
        <Navbar.Toggle aria-controls="navbarSupportedContent"/>
      </div>
      <Navbar.Brand className="mx-auto">
        <Nav.Link as={NavLink} to="/home" exact={true} isActive={() => location.pathname === '/home'} onClick={handleClick}>
          <img src={image !== '' ? image : logo} height={40}/>
        </Nav.Link>
      </Navbar.Brand>
      <div className="ml-auto d-lg-none">
        <div style={{ display: "flex" }}>
          {location.pathname !== '/shop' && ( 
            <NavLink to="#" className='cart-icon' style={{ marginRight: '10px' }} onClick={toggleDropdown}>
              <FaSearch style={{ color: 'white' }} size={22} />
            </NavLink>
          )}
          {isDropdownOpen && <Search handleSearch={handleSearch} searchQuery={searchQuery} setSearchQuery={setSearchQuery} searchInputRef={searchInputRef} />}
          <NavLink as={NavLink} to="/cart" className='cart-icon' style={{marginRight: '10px'}} onClick={handleClick}>
            <StyledBadge badgeContent={storedItems !== null ? storedItems.length : 0}>
              <FaShoppingCart style={{color: "white"}} size={25}/>
            </StyledBadge >
          </NavLink>
        </div>
      </div>
      <Navbar.Collapse id="navbarSupportedContent">
        <Nav className="me-auto">
          <Nav.Link as={NavLink} to="/home" exact={true} isActive={() => location.pathname === '/home'} onClick={handleClick}>Início</Nav.Link>
          <Nav.Link as={NavLink} to="/shop" isActive={() => location.pathname === '/shop'} onClick={handleClick}>Store</Nav.Link>
          <Nav.Link as={NavLink} to="/portfolio" isActive={() => location.pathname === '/portfolio'} onClick={handleClick}>Quem Somos</Nav.Link>
          <Nav.Link as={NavLink} to="/contact" isActive={() => location.pathname === '/contact'} onClick={handleClick}>Contato</Nav.Link>
        </Nav>
        <div className="ml-auto d-none d-lg-block" style={{ display: "flex", alignItems: "center"}}>
          <div style={{ display: "flex" }}>
            {location.pathname !== '/shop' && ( 
              <NavLink to="#" className='cart-icon' style={{ marginRight: '10px' }} onClick={toggleDropdown}>
                <FaSearch style={{ color: 'white' }} size={22} />
              </NavLink>
            )}
            {isDropdownOpen && <Search handleSearch={handleSearch} searchQuery={searchQuery} setSearchQuery={setSearchQuery} searchInputRef={searchInputRef} />}
            <NavLink as={NavLink} to="/cart" className='cart-icon' style={{marginRight: '10px'}} onClick={handleClick}>
              <StyledBadge badgeContent={storedItems !== null ? storedItems.length : 0}>
                <FaShoppingCart style={{color: "white"}} size={25}/>
              </StyledBadge >
            </NavLink>
          </div>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}

export function Search({ handleSearch, searchQuery, setSearchQuery, searchInputRef }) {

  useEffect(() => {
    searchInputRef.current.focus();
  }, []);

  return (
    <div style={{ width: '100%', backgroundColor: 'black', position: 'absolute', top: '100%', left: 0, zIndex: 999 }}>
      <Form className="header-search pt-lg-0 pt-0" onSubmit={handleSearch}>
        <div className="input-group">
        <FormControl
          ref={searchInputRef}
          type="search"
          className="mr-2 main-search"
          aria-label="Pesquisar"
          placeholder='Pesquisar...'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="input-group-append">
          <Button
            className="btn btn-main-search"
            type="submit"
          >
            OK
          </Button>
        </div>
      </div>
      </Form>
    </div>
  )
}