import React, { useEffect } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import AsyncComponent from './asyncComponent';
import PrivateRoute from '../Utils/PrivateRoute';
import Dashboard from '../Admin/Home';

import Header from '../Header';
import Footer from '../Footer';

export default function Router() {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();

  useEffect(() => {
    if (pathname === '/') {
      history.push('/home');
    }
  }, [pathname, history]);

  return (
    <div>
      {(pathname !== '/dashboard' && !pathname.includes('/dashboard/')) && pathname !== '/login' && <Header />}
      
      <Switch>
        <PrivateRoute path="/dashboard" component={Dashboard}/>
        
        <Route exact path="/home" render={() => (
          <AsyncComponent
            importComponent={() => import('../Home')}
          />
        )} />
        <Route exact path="/shop" render={() => (
          <AsyncComponent
            importComponent={() => import('../Shop')}
          />
        )} />
        <Route exact path="/product/:slug" render={() => (
          <AsyncComponent
            importComponent={() => import('../Product')}
          />
        )} />
        <Route exact path="/cart" render={() => (
          <AsyncComponent
            importComponent={() => import('../Cart')}
          />
        )} />
        <Route exact path="/portfolio" render={() => (
          <AsyncComponent
            importComponent={() => import('../Portfolio')}
          />
        )} />
        <Route exact path="/gallery/:id" render={() => (
          <AsyncComponent
            importComponent={() => import('../Gallery')}
          />
        )} />
        <Route exact path="/contact" render={() => (
          <AsyncComponent
            importComponent={() => import('../Contact')}
          />
        )} />
        <Route exact path="/links/exchangesReturns" render={() => (
          <AsyncComponent
            importComponent={() => import('../Links/exchangesReturns')}
          />
        )} />
        <Route exact path="/links/paymentShipping" render={() => (
          <AsyncComponent
            importComponent={() => import('../Links/paymentShipping')}
          />
        )} />
        <Route exact path="/links/privacyPolicies" render={() => (
          <AsyncComponent
            importComponent={() => import('../Links/privacyPolicies')}
          />
        )} />
        <Route exact path="/login" render={() => (
          <AsyncComponent
            importComponent={() => import('../Admin/Login')}
          />
        )} />
        <Route exact path="*" render={() => (
          <AsyncComponent
            importComponent={() => import('../NotFound')}
          />
        )} />
      </Switch>

      {(pathname !== '/dashboard' && !pathname.includes('/dashboard/')) && pathname !== '/login' && <Footer />}
    </div>
  );
}