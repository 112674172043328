import React from 'react';
import { Link } from 'react-router-dom';
import { IoMdArrowDropright } from 'react-icons/io';

export default function SubMenu({ submenu, activeSubMenu, handleSubMenuClick }) {
  return (
    <li className='pt-3' style={{ listStyle: 'none' }}>
      <Link
        to={submenu.href}
        className={`link-no-underline ${activeSubMenu === submenu.href ? 'active' : ''}`}
        onClick={() => handleSubMenuClick(submenu.href)}
      >
        <IoMdArrowDropright className="info-icon" size={25} />
        {submenu.text}
      </Link>
    </li>
  );
}