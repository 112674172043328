import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import app from '../../services/app';

export default function Footer() {
  const [name, setCompanyName] = useState('');
  const [whatsapp, setCompanyWhatsapp] = useState('');
  const [facebook, setCompanyFacebook] = useState('');
  const [instagram, setCompanyInstagram] = useState('');
  const [mail, setCompanyMail] = useState('');
  const [city, setCompanyCity] = useState('');
  const [address, setCompanyAddress] = useState('');
  const [resume, setCompanyResume] = useState('');
  const [image, setCompanyImage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
       
        const company = await app.get('/api/company/v1/view/1');
  
        setCompanyName(company.data.name);
        setCompanyWhatsapp(company.data.whatsapp);
        setCompanyFacebook(company.data.facebook);
        setCompanyInstagram(company.data.instagram);
        setCompanyMail(company.data.mail);
        setCompanyCity(company.data.city);
        setCompanyAddress(company.data.address);
        setCompanyResume(company.data.resume);
        setCompanyImage(company.data.image);

      } catch (error) {
        console.error('Falha ao buscar dados do usuário:', error);
      }
    };
  
    fetchData();
  }, []);

  return (
    <footer className="text-center text-lg-start text-muted" style={{ backgroundColor: "rgba(0, 0, 0, 0.990)" }}>
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom" style={{ color: "#fff" }}>
        <div className="me-5 d-none d-lg-block">
          <span>Siga-nos nas redes sociais</span>
        </div>
        <div>
          <a href={facebook} target='_blank' className="me-4 text-reset"><FaFacebookF className='social-icon'/></a>
          <a href={instagram} target='_blank' className="me-4 text-reset"><FaInstagram className='social-icon'/></a>
          <a href={`https://api.whatsapp.com/send?phone=+55${whatsapp.replace(/\D/g, "")}&text=Olá`} target='_blank' className="me-4 text-reset"><FaWhatsapp className='social-icon'/></a>
        </div>
      </section>
      <section>
        <Container style={{ color: "#fff" }}>
          <Row className="mt-3">
            <Col md={4} lg={4} xl={4} className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                {name}
              </h6>
              <p>
                {resume}
              </p>
            </Col>
            <Col md={1} lg={1} xl={1} className="mx-auto mb-4"></Col>
            <Col md={3} lg={3} xl={3} className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Links</h6>
              <p style={{ textDecoration: 'underline' }}>
                <Nav.Link as={NavLink} to="/links/privacyPolicies">Políticas de Privacidade</Nav.Link>
              </p>
              <p style={{ textDecoration: 'underline' }}>
                <Nav.Link as={NavLink} to="/links/exchangesReturns">Trocas e Devoluções</Nav.Link>
              </p>
              <p style={{ textDecoration: 'underline' }}>
                <Nav.Link as={NavLink} to="/links/paymentShipping">Pagamentos e Envios</Nav.Link>
              </p>
            </Col>
            <Col md={4} lg={4} xl={4} className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contato</h6>
              <p>{address}</p>
              <p>{city}</p>
              <p>{mail}</p>
              <p>{whatsapp}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="text-center p-4" style={{ backgroundColor: "rgba(0, 0, 0, 1)", color: "#fff" }}>
        {name} © { new Date().getFullYear() }. Todos os direitos reservados
      </div>
    </footer>
  );
}