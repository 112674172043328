const storageUpdate = {
  refreshCart: false,
  updateCartCallback: () => {}, // Função de callback inicial vazia

  setRefreshCart(value) {
    storageUpdate.refreshCart = value;
    storageUpdate.updateCartCallback(); // Chama o callback sempre que o valor for atualizado
  },

  setUpdateCartCallback(callback) {
    storageUpdate.updateCartCallback = callback; // Define a função de callback
  }
};

export default storageUpdate;